<template>
    <header>
        <h1><img src="../assets/img/buzzer-icon.png" /> Buzzer.js</h1>
        <h3>Simple buzzer app for debate panels</h3>
    </header>
</template>

<style scoped>
h1 {
    border-bottom: solid 1px white;
    margin-bottom: 0;
    padding-bottom: 5px;
}

h3 {
    margin-top: 10px;
}

img {
    width: 32px;
    height: 32px;
}
</style>
